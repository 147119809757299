import React from 'react';
import { Spacing, Text, Link, Icon, mediaQueries } from '@reservamos/elements';
import { css, useTheme } from '@emotion/react';
import NextLink from 'next/link';
import LayoutContainer from '../../atoms/LayoutContainer';
import assetUrl from '../../../utils/assetUrl';
import { rollBitsLinks } from '../../../constants/links';

const year = new Date().getFullYear();

const FooterRB = () => {
  const background = css`
    background-color: #005dff;
    padding: 40px 0px 40px;
  `;
  const { colors } = useTheme();
  const li = css`
    color: ${colors.white};
  `;
  const borderBottom = css`
    ${mediaQueries.small} {
      border-bottom: 2px solid ${colors.white};
      padding-bottom: 25px;
      margin-bottom: 25px;
    }
  `;
  const divider = css`
    border-right: 2px solid ${colors.white};
    padding-right: 20px;
    min-height: 150px;
    ${mediaQueries.small} {
      border-right: none;
    }
  `;

  return (
    <div css={background}>
      <LayoutContainer paddingMobile>
        <Spacing size="L" isResponsive justifyContent="space-between" flexGrow>
          <div css={divider}>
            <Spacing vertical size="S">
              <Text weight="bold" size="XXL" color="white">
                Contáctanos
              </Text>
              <Text color="white">servicioaclientes@roll-bits.com</Text>
              <Text color="white" size="S">
                O visita nuestra sección de
              </Text>
              <Link
                href={rollBitsLinks.faqs}
                type="white"
                text="Preguntas Frecuentes"
                newTab
              />

              <Icon type={assetUrl('icons/footer.png')} size="L" />
            </Spacing>
          </div>
          <Spacing isResponsive justifyContent="space-between">
            <Spacing vertical size="XS">
              <Text weight="bold" size="XL" color="white">
                Nosotros
              </Text>
              <ul>
                <li css={li}>
                  <Link
                    href={rollBitsLinks.faqs}
                    type="white"
                    text="Preguntas Frecuentes"
                    newTab
                    textDecorationLine="none"
                    weight="regular"
                  />
                </li>
                <li css={li}>
                  <Link
                    href={rollBitsLinks.privacy}
                    type="white"
                    text="Aviso de Privacidad"
                    newTab
                    textDecorationLine="none"
                    weight="regular"
                  />
                </li>
                <li css={li}>
                  <Link
                    href={rollBitsLinks.termsAndConditions}
                    type="white"
                    text="Términos y Condiciones"
                    newTab
                    textDecorationLine="none"
                    weight="regular"
                  />
                </li>
              </ul>
            </Spacing>
            <Spacing vertical>
              <Text weight="bold" size="XL" color="white">
                Ligas de Interés
              </Text>
              <ul>
                <li css={li}>
                  <Link
                    href={rollBitsLinks.billing}
                    type="white"
                    text="Facturación"
                    newTab
                    textDecorationLine="none"
                    weight="regular"
                  />
                </li>
                <li css={li}>
                  <Link
                    href={rollBitsLinks.faqs}
                    type="white"
                    text="Comentarios y Sugerencias"
                    newTab
                    textDecorationLine="none"
                    weight="regular"
                  />
                </li>
                <li css={li}>
                  <NextLink href="/doters">
                    <Link
                      href="/doters"
                      type="white"
                      text="Doters"
                      textDecorationLine="none"
                      weight="regular"
                    />
                  </NextLink>
                </li>
              </ul>
            </Spacing>
            <div css={borderBottom} />
          </Spacing>
        </Spacing>
        <Spacing
          size="L"
          alignItems="center"
          justifyContent="space-between"
          isResponsive
        >
          <Spacing size="L">
            <a href={rollBitsLinks.facebook} target="_blank" rel="noreferrer">
              <Icon
                type={assetUrl('social/facebook-white.png')}
                alt="Facebook"
              />
            </a>
            <a href={rollBitsLinks.instagram} target="_blank" rel="noreferrer">
              <Icon
                type={assetUrl('social/instagram-white.png')}
                alt="Instagram"
              />
            </a>
          </Spacing>
          <Spacing size="XL">
            <Icon type={assetUrl('payment/visa-white.png')} size="XL" />
            <Icon type={assetUrl('payment/mastercard-white.png')} size="XL" />
            <Icon type={assetUrl('payment/amex-white.png')} size="XL" />
            <Icon type={assetUrl('payment/safe-payment.png')} size="XL" />
          </Spacing>
        </Spacing>
        <Spacing size="L">
          <Text color="white" size="S">
            © {year} Roll & Bits powered by Reservamos.Todos los derechos
            reservados.
          </Text>
        </Spacing>
      </LayoutContainer>
    </div>
  );
};
FooterRB.propTypes = {};

export default FooterRB;
